@import "../../styles/resources";

$border: 1px solid $mystic;

.table {
  border-collapse: collapse;

  &.fullWidth {
    width: 100%;
  }

  &.fixed {
    table-layout: fixed;
  }
}

.thead {
  border: 1px solid $table-border;

  tr {
    background-color: #fff;
    td,
    th {
      position: -webkit-sticky;
      position: sticky;
      top: 53px;
      background: $white;
      z-index: 1;
      border-bottom: 1px solid $table-header-bottom-border;
    }
  }
}

.modelCodeHeaderRow {
  height: 25px;

  &.noBorder {
    border: 0;
  }
}

.horizontalScrolLTable {
  overflow-x: auto;
  overflow-y: visible;
  margin-top: -10px;
  padding-top: 10px;
}

.twoTableWrapper {
  display: flex;
  width: 100%;
}

.headerRow {
  height: 80px;
  min-height: 80px;
}

.leftTableTH {
  padding: 10px;
  text-align: left;
  font-family: $font-family-roboto;
  @include fontSize(14px);
  color: $navy;
  font-weight: 500;

  &.clickable {
    cursor: pointer;
  }
}

.headerButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  font: inherit;
  color: $primary-color;
  text-align: left;
  padding: 0;
  transition: color $transition-timing;
  outline: none;
  cursor: pointer;

  &:hover {
    color: $tertiary-color;
  }
}
