@import "../../../../../styles/resources";

.comparePopoverContainer {
  position: initial;
}

.flagsCellWrapper {
  padding-left: 10px;
  position: relative;

  .flagsCell {
    width: 110px;
    font-family: $font-family-roboto;
    display: flex;
    height: 100%;

    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include fontSize(12px);

    .notesPopoverClass {
      &:only-child {
        margin: auto 0;
      }
    }

    .notes {
      width: auto;
      padding: 0;
      padding-left: 3px;
      height: auto;
      font-family: $font-family-roboto;
      font-weight: normal;
    }

    .checkbox {
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;

        &::before {
          position: initial;
          width: 13px;
          height: 13px;
        }

        &::after {
          left: 4px;
          top: 1px;
          width: 4px;
          height: 8px;
        }
      }

      .checkboxLabel {
        @include fontSize(10px);
        font-family: $font-family-roboto;
        color: $slate;
        padding-left: 4px;
      }
    }

    .msrpPopoverClass > div:nth-child(2) {
      left: 40px;
      top: -92px;

      &::before {
        right: 290px;
        transform: rotate(315deg);
        top: 85px;
      }
    }
  }
}

.badgeText {
  color: goldenrod;
}
