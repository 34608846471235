@import "../../../../../../styles/resources";

$border: 1px solid $mystic;

.compareFeatureColumn {
  display: flex;
  flex-direction: column;
}

.compareFeatureButtonWrapper {
  width: 100%;
  display: flex;
}

.highlightedOn {
  background-color: $blue;
  color: $white;
  width: 60%;
  margin: 0 auto;
}

.highlightedOff {
  background-color: $white;
  color: $blue;
  width: 60%;
  margin: 0 auto;
}

.td {
  border-right: $border;
}

.setting {
  text-align: center;
  @include fontSize(14px);
  font-weight: bold;
  font-family: $font-family-roboto;
  color: $shuttle-grey;
  border: none;
  resize: none;
  height: 100%;
  background-color: transparent;
  width: 160px !important;

  // different colors based on setting (S, P, O, --)
  &.s,
  &.S {
    color: $apple;
  }
  &.p,
  &.P {
    color: $blue;
  }
  &.o,
  &.O {
    color: $purple;
  }
  &.a,
  &.A {
    color: $blue;
  }
  &.changeLogStyle {
    color: $red;
  }
  &.capitalLetter {
    text-transform: uppercase;
  }
}

.oddRowColor {
  background-color: $lighter-slate;
}

.tokenContainer {
  width: 140px !important;
  overflow: hidden;
}

.tokenDisplay {
  text-align: center;
  padding: 0;
  overflow-x: hidden;
}

.isRequired {
  position: absolute;
  top: 8px;
  right: 8px;
}

.invalid {
  position: relative;
  color: $red;

  input {
    &::-webkit-input-placeholder {
      color: $red;
    }
    &::-moz-placeholder {
      color: $red;
    }
    &:-ms-input-placeholder {
      color: $red;
    }
    &::-ms-input-placeholder {
      color: $red;
    }
    &::placeholder {
      color: $red;
    }
  }
}

.lexusAgencySpecsStyle {
  width: 160px !important;
}

.iconPen {
  color: $blue;
  font-size: 13px;
  padding-left: 2px;
}

.syncValueChanged {
  background-color: $input-error-background;
}
