@import "../../../../../../styles/resources.scss";

.name {
  background: $white;
}

.category,
.description {
  letter-spacing: 0.22px;
  line-height: 16px;
  display: flex;
  padding-left: 10px;
  align-items: center;
}

.categoryCell {
  border: 1px solid $mystic;
  font-size: 14px;
}

.description {
  width: 100%;
  font-size: 12px;
}

.notesContainer,
.statusContainer {
  height: 11px;
  color: $slate;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 11px;

  label {
    &::before {
      height: 13px;
      width: 13px;
    }

    &::after {
      height: 6px;
      width: 3px;
    }
  }
}

.notesContainer {
  margin-top: 15px;

  label {
    &::before {
      border-color: $dark-green;
    }
  }

  input:checked + label::after {
    border-color: $dark-green;
  }
}

.statusContainer {
  margin-top: 10px;

  label {
    &::before {
      border-color: $red;
    }
  }

  input:checked + label::after {
    border-color: $red;
  }
}

.chkboxLabel {
  display: inline-flex;
  margin-top: 3px;
}

.tableTextArea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  padding-top: 25px;
  background: inherit;
  outline: none;
}

.grayRow {
  height: 20px;
  background-color: $gray;
}

.copyWrapper {
  margin-top: 10px;
}

.titleInput {
  height: 20px;
  width: 393px;
  padding-left: 0px;
  outline: none;
}

.copyInput {
  width: 400px;
}

.titleLabel,
.copyLabel {
  display: inline-flex;
  width: 40px;
  margin-left: 10px;
  color: $slate;
  font-size: 13px;
}

.titleInput,
.copyInput {
  display: inline-block;
}

.hei {
  height: 25px;
}

.errorText {
  color: black !important;
  border: 1px $input-error-border solid;
  background-color: $input-error-background;
}

.acceptChanges {
  margin: 1rem;
  margin-left: 0.5rem;
}

.splitBtn {
  max-width: 100px;
}

.iconBlue {
  color: $blue;
}

.descriptionCell {
  section {
    width: 100%;
  }

  & > div {
    margin: 5px;

    input {
      width: 100%;
    }
  }
}

.splitMenu {
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: inherit;
  }
}
