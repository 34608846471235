$blue: #177de3;
$light-blue: #edf6ff;
$slate: #89969f;
$light-slate: #e6eaef;
$lighter-slate: #f4f7fb;
$light-gray: #cdd1d4;
$lighter-gray: #f2f2f2;
$dark-green: #00b4a0;
$green: #43b131;
$light-green: #f6fbf6;
$navy: #152935;
$white: #fff;
$red: #e0182d;
$light-red: #f9ddde;
$gray: #767676;
$mine-shaft: #333333;
$geyser: #dfe3e6;
$mystic: #edf0f5;
$apple: #4cba38;
$purple: #5600a2;
$shuttle-grey: #5a6872;
$buttercup: #f5a623;
$black: #000000;
$light-black: #4a4a4a;
$light-yellow: #fdf7ac;
$light-teal: #cdecec;

$primary-color: $navy;
$secondary-color: $slate;
$tertiary-color: $blue;

// Input
$input-border: $light-gray;
$input-placeholder: $light-gray;

$input-error-border: $red;
$input-error-background: $light-red;
$input-error-color: $red;
$input-error-placeholder-color: #e0aaaa;

$form-control-height: 2.25em;

// Select
$select-caret: $secondary-color;

// Table
$table-row-background-even: $white;
$table-row-background-odd: $lighter-slate;
$table-row-height: 88px;
$table-border: $lighter-gray;
$table-header-bottom-border: $light-gray;

$table-input-border: $light-slate;
$table-body-font-size: 14px;
$table-body-font-size-small: 10px;

$vd-table-dropdown: 118px;
$vd-table-description: 270px;
$vd-table-changes: 270px;
$vd-table-flags: 121px;
$vd-table-acceptApply: 72px;
$vd-table-applicability: 160px;

$bp-table-name: 140px;
$bp-table-category: 270px;

$sidemenu-background: $primary-color;
$sidemenu-link-text: $white;
$sidemenu-link-hover-text: $white;
$sidemenu-link-hover-background: #acb5bb;

$padding-h: 16px;

// Animation props
$transition-timing: 0.2s cubic-bezier(0.3, 0.38, 0.15, 1);

// Font
$font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
