@import "../../../../../../../styles/resources.scss";

.tableText {
  border-radius: 3px;
  color: $secondary-color;
  font-size: 14px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100px;

  &,
  * {
    box-sizing: border-box;
  }

  .actionBtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  thead {
    tr {
      td {
        vertical-align: middle;
        font-size: $table-body-font-size;
        padding: 5px;
      }
    }
  }

  tbody {
    tr {
      height: 48px;
      &:hover {
        box-shadow: 0 5px 8px -5px $gray !important;
        transform: scale(1);
        cursor: pointer;
      }
    }
  }
}

.codeRed {
  color: $red;
}

.goLive {
  color: $secondary-color;
}

.isLive {
  color: $green;
}

.boldLabel {
  font-weight: bold;
  margin-top: 25px;
}

.modelNumbers {
  color: $tertiary-color;
  font-weight: bold;
}

ul {
  margin-bottom: 0px;
  margin-top: 5px;
}
