@import "../../../../styles/resources";

.tdprButton {
  font-size: 9px !important;
  position: absolute;
  padding: 0px 3px !important;
  background-color: $light-blue;
  z-index: 1;
  color: $blue;
}

.warningIcon {
  margin-right: 5px;
  color: $red;
}

.hasTMNAChanges {
  border: 1px solid $red;
  color: $red;
  background-color: $light-red;
}

.onlySpanish {
  top: -30px;
  left: 0px;
}

.featureSpecs {
  top: -9px;
  left: 0px;
}

.border {
  position: relative;
}
