@import "../../styles/resources.scss";

.checkbox {
  position: relative;
  white-space: nowrap;

  &.wrap {
    white-space: normal;
  }

  input {
    display: none;

    &:disabled + label {
      cursor: default;
    }
  }
  label {
    position: relative;
    padding-left: 24px;
    outline: none;
    cursor: pointer;

    &.bold {
      font-weight: bold;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid $input-border;
      border-radius: 3px;
      background-color: $white;
    }

    &::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 3px;
      border-bottom: 3px solid;
      border-right: 3px solid;
      border-color: $white;
      width: 4px;
      height: 8px;
      transform: rotate(45deg);
      transition: border-color $transition-timing;
    }
  }

  &.small {
    label {
      padding-left: 18px;

      &::before {
        width: 12px;
        height: 12px;
      }

      &::after {
        left: 5px;
        top: 3px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        border-color: $white;
        width: 3px;
        height: 7px;
      }
    }
  }
}

// themes
.default {
  label {
    color: $primary-color;
  }

  input:checked + label::after {
    border-color: $blue;
  }
}

.redOutline {
  label {
    &::before {
      border-color: $red;
    }
  }

  input:checked {
    & + label::before {
      background-color: $light-red;
    }
    & + label::after {
      border-color: $red;
    }
  }
}

.greenOutline {
  label {
    &::before {
      border-color: $green;
    }
  }

  input:checked {
    & + label::before {
      background-color: $light-green;
    }
    & + label::after {
      border-color: $green;
    }
  }
}

.grayOutline {
  label {
    &::before {
      border-color: $light-gray;
    }
  }

  input:checked {
    & + label::before {
      background-color: $light-gray;
    }
    & + label::after {
      border-color: $gray;
    }
  }
}

.greenCell {
  label {
    &::before {
      border: 0;
      background-color: transparent;
      content: "- -";
    }

    &::after {
      border-color: transparent;
    }
  }

  input:disabled {
    & + label::before {
      content: "";
    }
  }

  input:checked {
    & + label::before {
      content: "";
    }

    & + label::after {
      border-color: $green;
    }
  }
}
