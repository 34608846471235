@import "../../../../../../styles/resources";

.cellSpacer {
  width: 10px;
  min-width: 10px;
  background: $white;
}

.table {
  margin-right: 10px;
}

.trimHeaderRow {
  width: 100%;
  height: 20px;
  border-bottom: none;
}

.trimHeaderNameCell {
  font-size: 12px;
  color: $blue;
  background-color: $lighter-slate;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-weight: 700;
}

.headerCell {
  position: inherit !important;
  top: 0;
  z-index: 3;
  height: 0px;
}

.headerRow {
  display: flex;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  background-color: $white;
}

.headerDetail {
  width: 160px;
  position: relative;

  .type {
    font-size: $table-body-font-size-small;
  }
  .interiorName {
    font-size: $table-body-font-size-small;
    letter-spacing: 0.14px;
    text-align: center;
    color: $slate;
    vertical-align: middle;
    font-weight: 600;
  }
  .modelCode {
    color: $slate;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
  }

  .status {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .msrp {
    color: $green;
    font-size: 11px;
  }
}

.bodyRow {
  height: 88px;
}

.checkboxCell {
  min-width: 104px;

  label {
    padding-left: 0px;
    outline: none;
    cursor: pointer;

    &::before {
      content: "--";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      border-color: transparent;
    }
  }

  input:checked + label {
    &::before {
      content: "";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      content: "";
      left: 6px;
      top: 2px;
      border-color: $apple;
    }
  }
}

.modelCodesWrapper {
  height: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  justify-content: center;
}

.modelCodesPopover {
  width: max-content;
  max-width: 200px;
  color: $light-black;
}

.modelCodesToggle {
  font-size: 11px;
  padding-left: 5px;
  cursor: pointer;
  color: $light-black;
}
