@import "../../../../styles/resources";

.checkbox {
  min-width: 104px;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;

    &::before {
      position: initial;
    }

    &::after {
      left: 6px;
      top: 2px;
    }
  }

  .checkboxLabel {
    @include fontSize(10px);
    font-family: $font-family-roboto;
    color: $slate;
    padding-left: 4px;
  }
}

.iconButton {
  line-height: initial;
  padding-left: 0px;
}

.rightSide .resetIcon,
.iconButton .addFeatureIcon {
  width: 17px;
}
