@import '../../../../../../styles/resources';

.linkSpec {
  position: relative;
  color: $slate;
  top: 33px;
  margin: 5px;
  display: initial;
  h4 {
    color: rgb(0, 0, 0);
  }
}

.iconBlue {
  color: $blue;
}

.modelPopover {
  width: 400px;
  min-height: 200px;
}

.ClDescription {
  color: $gray;
}

.removeSelection {
  width: 100%;
  padding: 5px 0;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #89969f;
  span {
    padding-right: 5px;
    color: $gray;
  }
}
.removeSelectionBtn {
  font-size: 12px;
}

.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;
}

.acceptChangesThumbsUp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.warningIcon {
  margin-right: 5px;
}

.categoryColumn {
  padding-right: 25px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.tdprButton {
  position: unset;
}
