@import "../../../../../../styles/resources.scss";

.modelApplicabilityList {
  display: flex;
  flex-direction: column;
}

.modelApplicabilityItem {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.iconTextButton {
  span {
    font-size: 10px;
  }
}

.modelApplicabilityGrades {
  margin-left: 24px;
}

.checkboxSquare::after {
  background: $blue;
  border-bottom: unset !important;
  border-right: unset !important;
  transform: unset !important;
  transition: unset !important;
  width: 11px !important;
  height: 11px !important;
  left: 3.5px !important;
  top: 4.5px !important;
  border-radius: 3px;
}
