@import "../../styles/resources.scss";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: calc(0.375em - 1px) 0.75em;
  height: 3em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 10px;
  font-weight: bold;
  width: inherit;
  border: none;
  line-height: 1.5;
  background-color: transparent;
  color: $secondary-color;
  cursor: pointer;
  outline: 0;

  transition: color $transition-timing;

  &:hover {
    color: $blue;
  }
}

.icon {
  display: inline-block;
  margin-right: 8px;
  font-size: 20px;
}

.smallIcon {
  font-size: 10px;
}

.iconFlag,
.iconPlus,
.iconDuplicate,
.iconDownload,
.iconRandom,
.iconChevronDown,
.iconChevronUp,
.iconSplit,
.iconTrash {
  color: $blue;
}

.iconUndo {
  color: $blue;
}

.iconCircle {
  color: $green;
}

.redIconCircle {
  color: $red;
}

.purpleIconCircle {
  color: $purple;
}

.iconRemove,
.iconEdit {
  color: $secondary-color;
}
