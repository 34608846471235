@import "../../../../../../styles/resources";

$border: 1px solid $mystic;

.rightBorder {
  border-right: $border;
}

.leftBorder {
  border-left: $border;
}
