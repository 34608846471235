@import "../../../../../../styles/resources";

.checkbox {
  display: inline-block;
  top: 3px;
  margin-left: 16px;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;

    &::before {
      position: initial;
      width: 13px;
      height: 13px;
    }

    &::after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
    }
  }
}

.checkboxLabel {
  @include fontSize(10px);
  color: $navy;
  padding-left: 8px;
}
