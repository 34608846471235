@import "../../../../../styles/resources";
$border: 1px solid $mystic;

.root {
  min-width: 40px;
  text-align: center;
}

.button {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.icon {
  color: $light-gray;
  width: 15px;
  &.active {
    color: $blue;
  }
}

.popoverElementClass {
  top: 25px;
  left: -18px;
}
