@import "../../../../../../styles/resources.scss";

.content {
  padding: 16px 22px;
}

.hideIcon {
  visibility: hidden;
}

.actionBtnsWrapper {
  display: flex;
  justify-content: flex-end;
}

.tableText {
  border-radius: 3px;
  color: $secondary-color;
  width: 20%;
  min-width: 190px;
  max-width: 190px;
  font-size: 14px;
}

.actionBtns {
  text-align: end;
  svg {
    font-size: 14px;
    &:hover {
      color: $tertiary-color;
    }
  }
}

.actionLabel {
  display: inline-flex;
  width: 70px;
}

.upDown {
  margin-right: 50px;
  margin-left: 5px;
}
