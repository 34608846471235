@import "../../styles/resources.scss";

.linksWrapper {
  padding: 20px;
  list-style-type: decimal;
  li {
    list-style-type: none;
  }
}

.envelope {
  color: $slate;
  display: inline-block;
  margin-bottom: 2px;
  padding: 1px;
}
