@import "../../../../../../styles/resources.scss";

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 37px;

  &,
  * {
    box-sizing: border-box;
  }

  .actionBtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .statusColumn {
    min-width: 320px;
  }

  thead {
    tr {
      color: $secondary-color;

      td {
        padding: 0 16px;
        vertical-align: middle;
        font-size: $table-body-font-size;
        font-weight: bold;

        .addModelYearCta,
        .removeCta,
        .cta {
          appearance: none;
          background-color: transparent;
          border: none;
          font-size: 10px;
          color: $secondary-color;
          text-align: left;
          width: 160px;
          padding: 0;
          cursor: pointer;
          transition: color $transition-timing;
          line-height: 0px;
          outline: none;

          svg {
            color: $secondary-color;
            font-size: 14px;
            &:hover {
              color: $tertiary-color;
            }
          }
        }
        .addModelYearCta,
        .removeCta {
          &:hover {
            color: $tertiary-color;
          }
        }
        .addModelYearCta {
          border-right: 1px solid $secondary-color;
          height: 19px;
          width: 115px !important;
        }
        .cta {
          font-size: $table-body-font-size;
          font-weight: bold;
        }
        .seriesName {
          color: $tertiary-color;
          font-size: 16px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          width: 200px;
          height: 18px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 48px;
      &:hover {
        box-shadow: 0 5px 8px -5px $gray !important;
        transform: scale(1);
        cursor: pointer;
      }
    }
  }
}

.addModelYearModal {
  width: 475px;
}
