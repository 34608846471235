@import "../../styles/resources.scss";

.dropdownEditFooter {
  border-top: 1px solid $slate;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scrollContainer {
  max-height: 200px;
  overflow: auto;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
