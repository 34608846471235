@import "../../../styles/resources";

.headerNotificationButtonContainer {
  position: relative;
}

.headerNotificationButton {
  color: $red;
  background-color: white;
  border: none;
  display: inline-flex;
  font-weight: bold;

  .headerNotificationButtonText {
    font-size: 12px;
    padding-left: 5px;
    font-weight: bold;
  }

  .count {
    position: absolute;
    top: -10px;
    left: 12px;
    height: 20px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    background: $red;
    color: $white;
    border-radius: 10px;
    font-size: 14px;
  }
}
