@import "../../styles/resources";

.popover {
  background-color: $mine-shaft !important;
  margin-top: 20px !important;
}

.dateSection {
  background-color: $mine-shaft;
  display: flex;
  flex-direction: column;
  width: 340px;
  color: white;
  font-weight: bold;
  font-size: medium;
}
