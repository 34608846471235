@import "../../../../styles/resources.scss";

.modelLabel,
.groupLabel {
  height: 16px;
  width: 41px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 16px;
}

.groupLabel {
  margin-left: 24px;
}

.inputBox {
  box-sizing: border-box;
  height: 38px;
  width: 152px;
  border: 1px solid #cdd1d4;
  border-radius: 4px;
  display: block;
  margin-top: 9px;
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.vehicleModalBody {
  width: 400px;
}

.groupDropdown {
  box-sizing: border-box;
  height: 38px;
  width: 135px;
  border: 1px solid #cdd1d4;
  border-radius: 3px;
  margin-top: 9px;
  outline: none;
  margin-left: 24px;
  button {
    border: none;
    font-size: 13px;
    line-height: 16px;
  }
}
