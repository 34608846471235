@import "../../../../../../styles/resources.scss";

.duplicateCta,
.createCta {
  box-sizing: border-box;
  height: 63px;
  width: 189px;
  border-radius: 2px;
  background-color: #f4f7fb;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    border: 1px solid $blue;
  }
}

.seperator {
  display: inline-flex;
  width: 47px;
  justify-content: center;
}

.content {
  font-size: 13px;
  color: $blue;
  outline: none;
}

.backCta {
  position: absolute;
  left: 15px;
}

.dropdownWrapper {
  width: 50%;
  float: left;
}

.modelYearDropdown {
  button,
  ul {
    width: 160px;
    outline: none;
  }
}

.modelYearLabel {
  height: 16px;
  width: 143px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 35px;
}

.yearTitle {
  display: flex;
}

.radioParent {
  display: flex;
  flex-direction: column;
}

.radio {
  margin-top: 10px;
}
