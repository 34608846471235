@import "../../../../styles/resources.scss";

$table-font-size: 13px;

.changeLogTitle {
  font-weight: normal;
}

.changeLogSubTitle {
  color: $tertiary-color;
}

.addModelColumn {
  text-align: right;
}

.hybrid {
  margin: 0 8px;
  font-size: 14px;
  color: $tertiary-color;
}

.isPublishableChbox {
  input:checked + label {
    &:after,
    &:before {
      border-color: $red !important;
    }
  }
}

.table {
  box-sizing: border-box;

  .modelRow {
    height: 55px;
  }

  .tbodyActionBar {
    tr {
      td {
        padding: 8px 0;
        color: black;
        font-size: 14px;
        font-weight: 600;

        &.changeLog {
          text-align: right;

          .changeLogCta {
            color: $tertiary-color;
            text-decoration: none;
            cursor: pointer;
            background-color: transparent;
            border: none;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .tbodyHeader {
    tr {
      transform: scale(1, 1);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

      td {
        padding: 16px 8px;
        font-size: $table-font-size;
      }
    }
  }

  .tbodyMain {
    tr {
      td {
        padding: 16px 8px;
        font-size: $table-font-size;

        &.publishCol {
          width: 50px;
          max-width: 150px;

          button {
            position: relative !important;
          }

          .container {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .hasNotChanges {
            justify-content: flex-end;
          }

          .hasChanges {
            justify-content: space-between;
          }
        }

        &.previewCol {
          width: 60px;
        }
        &.trimCol {
          width: 50px;
          color: $gray;
          font-size: $table-body-font-size-small;
        }
        &.descriptionCol {
          width: 400px;
        }
        &.katashikiCol {
          width: 200px;
        }
        &.ctaCol {
          width: 200px;
          text-align: right;
        }
        &.goLiveDateCol {
          width: 200px;
        }
      }
    }
  }

  .hide {
    visibility: hidden;
  }
}
.hide {
  visibility: hidden;
  display: none;
}
.addModel {
  padding: 0 0px;

  .row {
    display: flex;
    flex-wrap: wrap;
    font-size: $table-font-size;

    input {
      padding: 16px 8px;
    }

    & > div {
      flex: 0 0 210px;
      padding: 8px;

      &.long {
        flex: 0 0 270px;
      }

      &.longer {
        flex: 0 0 414px;
      }

      &.short {
        flex: 0 0 140px;
      }

      &.verySmall {
        flex: 0 0 90px;
      }
      &.checkbox {
        padding-top: 12px;
        label {
          &::before {
            left: 50%;
            top: 28px;
          }
          &::after {
            left: 55%;
            top: 30px;
          }
        }
      }

      .content {
        display: flex;

        input:nth-child(even) {
          margin-left: 12px;
        }
      }
    }

    .ctas {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
    }

    .right {
      justify-content: flex-end;
    }

    label {
      display: inline-block;
      font-weight: bold;
      margin-bottom: 8px;
      &.optional {
        font-weight: normal;
      }
    }

    input {
      width: 100%;
    }

    .hybridToggle {
      display: flex;
      align-items: center;
      height: 36px;

      label {
        margin-bottom: 0;
      }
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: flex-end;
  button {
    width: 130px;
  }
}

.katashikiContainer {
  position: relative;
  margin-right: 12px;
}

.katashikiDelete {
  position: absolute;
  top: 0;
}

.dropdown {
  background-color: $white;
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: 3px;
  min-height: 27px;
  padding: 8.3px 0px;
}

input.bigerHeight {
  height: 2.5rem;
}

.populate {
  padding: 0;
  height: auto;
  margin-left: 6px;
  line-height: 1;

  > span {
    font-size: 13px;
  }
}

.rejectNotesPopover {
  text-align: left;
}

.goLiveDate {
  color: $blue;
  cursor: pointer;
}

.publishedGoLiveDate {
  color: $blue;
  cursor: auto;
}

.publishedPastGoLiveDate {
  color: $slate;
  cursor: auto;
}

.actionBar {
  width: 26vw;
}

.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;
}

.syncTdPRRow {
  button {
    position: relative !important;
  }
}

.tableCellHeight {
  height: 200px;
}
