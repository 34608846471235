@import "../../../../styles/resources.scss";

.name {
  margin-top: 20px;
  width: 263px;
  & div[role="combobox"] {
    min-width: 263px;
    min-height: 27px;
    padding-left: 5px;
    padding-top: 2px;
  }
}

.nameFieldContainer {
  display: block;
}

.nameInputModelCodeContainer {
  display: flex;
}

.disabledColorRTE {
  border: 1px solid $light-gray;
  width: 263px;
  background-color: #fafafa;
}

.modelCode {
  margin-top: 20px;
  width: 35%;
  margin-left: 20px;
}

.intTypeDropdown {
  button,
  ul {
    min-width: 150px;
    outline: none;
  }
}

.all {
  font-weight: bold;
  margin-bottom: 16px;
  label {
    padding-bottom: 2px;
    &::before {
      top: -0.5px;
    }
  }
}

.trimChkbox {
  padding-left: 23px;
  margin-bottom: 16px;
  display: inline-grid;
}

.interiorSelected {
  background: $lighter-gray;
}

.cancelCta {
  position: relative;
  right: 196px;
}

.placeholder {
  color: $input-placeholder;
  position: relative;
}

.rejectNotes {
  margin-top: 10px;

  &Text {
    margin-left: 24px;
  }
}

.currencyInput {
  position: relative;
}

.currencyInput input {
  padding-right: 18px;
  width: 263px;
  margin-top: 19px;
  padding-left: 12px;
}

.currencyInput:after {
  position: relative;
  content: "$";
  left: -14px;
  bottom: -2px;
  font-size: 15px;
}

.multiLangCurrencyInput:after {
  position: relative;
  content: "$";
  left: -16px;
  bottom: -2px;
  font-size: 15px;
}

.msrp {
  width: 255px;
  margin-top: 20px;
}
.treeMenu {
  padding-left: 23px;
  margin-bottom: 16px;
  display: inline-block;
  width: 40%;

  label {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-bottom: 2px;
    &::before {
      top: 0;
    }
  }
}

.menuItems {
  height: 20px;
  display: table-caption;
  margin-top: 8px;
  margin-left: 10px;
}

.down {
  &::after {
    content: "";
    position: relative;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    transform: rotate(135deg);
    float: left;
    bottom: 18px;
    left: 134px;
    cursor: pointer;
  }
}

.up {
  &::after {
    transform: rotate(314deg);
    bottom: 10px;
    cursor: pointer;
  }
}

.acceptChanges {
  margin: 1rem;
  margin-left: 0;
}

.errorTextRte {
  & div[role="combobox"] {
    color: black !important;
    border-color: $input-error-border;
    background-color: $input-error-background;
  }
}
.errorText {
  color: black !important;
  border-color: $input-error-border;
  background-color: $input-error-background;
}
